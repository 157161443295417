import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextOptions from '../shared/richTextOptions'
import Layout from '../components/layout'
import SEO from '../components/seo'

const resource = ({ pageContext, location }) => {
  const { title, body, attachment, resourceUrl, resourcestore, purchaseLink } = pageContext
  const backLink = resourcestore ? '/resources/store' : '/resources'

  return (
    <Layout path={location.pathname}>
      <SEO title={title} />
      <div className="container-small container resource text-content">
        <Link to={backLink} className="back-link">
          &larr; {resourcestore ? 'Resource Store' : 'Resources'}
        </Link>
        <h1>{title}</h1>
        {documentToReactComponents(body.json, richTextOptions)}
        {attachment && (
          <a
            href={attachment.file.url}
            className="btn btn-secondary"
            style={{ padding: '10px', textTransform: 'none' }}
          >
            Download Attachment
          </a>
        )}
        {resourceUrl && (
          <>
            <br />
            <a
              href={resourceUrl}
              className="btn btn-secondary"
              style={{ padding: '10px', textTransform: 'none' }}
            >
              View Online
            </a>
          </>
        )}
        <br />
        <br />
        {purchaseLink && <a href={purchaseLink}>Purchase Now</a>}
        <p style={{ margin: '30px 0 20px 0', fontSize: '0.8em', borderTop: '1px solid #eee' }}>
          We want to provide the most up to date, relevant and engaging resources for our community
          involved in aspects or global mission. If you know if any Resources we can share,{' '}
          <a href="mailto:admin@missionsinterlink.org.au">please contact us.</a>
        </p>
      </div>
    </Layout>
  )
}

export default resource

resource.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired
}
